
import { Component, Prop, toNative, Vue, Watch } from 'vue-facing-decorator';
import { Twitter } from '@ckpack/vue-color';
import vClickOutside from 'click-outside-vue3';
import CustomBrandingViewModel from '@/entities/EventSetting/CustomBrandingViewModel';
import CustomBrandingThemePreview from './CustomBrandingThemePreview.vue';
import ImageProcess from '@/components/Common/ImageProcess.vue';
import ImageContainer from '@/components/Common/ImageContainer.vue';
import ImageContainerViewModel from '@/entities/ImageContainerViewModel';
import { app, Root } from '@/main';
@Component({
  components: {
    Twitter,
    vClickOutside,
    CustomBrandingThemePreview,
    ImageProcess,
    ImageContainer,
  },
})
class CustomBrandingTheme extends Vue {
  @Prop() private isCustomBranding!: boolean;
  @Prop() private customBrandingThemeData!: CustomBrandingViewModel;
  private localIsCustomBranding = false;
  private localCustomBrandingThemeData: CustomBrandingViewModel = CustomBrandingViewModel.createEmpty();
  private isMobileView = false;
  private isTabView = false;
  private isTabLandscapeView = false;
  private primaryBackgroundColor = {hex: ''};
  private primaryFontColor = {hex: ''};
  private menuBackgroundColor = {hex: ''};
  private menuFontColor = {hex: ''};
  private ctaBackgroundColor = {hex: ''};
  private ctaFontColor = {hex: ''};
  private isPaletteSelected = [false,false,false,false,false,false];
  private addIcon = require('@/assets/Images/add-icon-grey.svg');
  private showLogoInformationString = false;
  private showCTABtnInformationString = false;
  private lightModeLogoData: ImageContainerViewModel = ImageContainerViewModel.createEmpty();
  private darkModeLogoData: ImageContainerViewModel = ImageContainerViewModel.createEmpty();

  // Constants
  private readonly maxLogoSize = 2; // Max allowed size limit to upload logo
  private readonly maxLogoHeight = 60; // Max allowed height of logo to upload
  private readonly darModeLogoBackground = '#000';
  private readonly darkModeLogoDescriptionMargin = '25px 0px 0px'; // Margin for dark mode logo description

  private mounted() {
    // Check if it is mobile view
    if(window.innerWidth <= 480) {
      this.isMobileView = true;
    } else {
      this.isMobileView = false;
    }
    // Check if it is tab view
    if(window.innerWidth < 768) {
      this.isTabView = true;
    } else {
      this.isTabView = false;
    }
    // Check if it is landscape view of tab
    if(window.innerWidth >= 768 && window.innerWidth <= 1200) {
      this.isTabLandscapeView = true;
    } else {
      this.isTabLandscapeView = false;
    }
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    app.use(vClickOutside);
    this.localCustomBrandingThemeData = Object.assign({}, this.customBrandingThemeData);
    this.localIsCustomBranding = this.isCustomBranding;
    // Set default colors in the palette
    this.SetDefaultColors();
    // Set data for light mode logo
    this.SetLightModeLogoData();
    // Set data for dark mode logo
    this.SetDarkModeLogoData();
    Root.on('validate-light-mode-logo',(val: boolean)=> {
      this.ValidateLightModeLogo(val);
    });
  }

  // Used to enable validation on light mode logo once the submit btn is clicked
  private ValidateLightModeLogo(val: boolean) {
    this.lightModeLogoData.ValidateLogo = val;
  }

  // Used for setting up data for light mode logo
  private SetLightModeLogoData() {
    if(this.localCustomBrandingThemeData !== null && this.localCustomBrandingThemeData !== undefined) {
      this.lightModeLogoData.ImageBackground = this.localCustomBrandingThemeData.PrimaryBackgroundColor;
      this.lightModeLogoData.IsLogoMandatory = true;
      this.lightModeLogoData.ImageHeading = this.$t('Logo').toString();
      this.lightModeLogoData.ShowImageHeading = true;
      this.lightModeLogoData.ImageUrl = this.localCustomBrandingThemeData.LightModeLogoUrl;
      this.lightModeLogoData.ValidateLogo = false;
    }
  }

  // Used for setting up data for dark mode logo
  private SetDarkModeLogoData() {
    if(this.localCustomBrandingThemeData !== null && this.localCustomBrandingThemeData !== undefined) {
      this.darkModeLogoData.ImageBackground = this.darModeLogoBackground;
      this.darkModeLogoData.ImageHeading = this.$t('EventSetting.Customization.LogoForDarkMode');
      this.darkModeLogoData.ShowImageHeading = true;
      this.darkModeLogoData.ImageUrl = this.localCustomBrandingThemeData.DarkModeLogoUrl;
      this.darkModeLogoData.ImageDescription = this.GetLogoWarningMessage();
      this.darkModeLogoData.ShowImageDescription = this.isMobileView ? false : true;
      this.darkModeLogoData.ShowTooltip = true;
      this.darkModeLogoData.TooltipText = this.$t('EventSetting.Customization.DarkModelTooltip').toString();
      this.darkModeLogoData.DescriptionMargin = this.darkModeLogoDescriptionMargin;
    }
  }

  private GetLogoWarningMessage() {
    return this.$t('EventSetting.CustomTheme.LogoWarning',{size: this.maxLogoSize,height: this.maxLogoHeight});
  }

  private IsCustomBranding(val: boolean) {
    this.$emit('set-branding-type', val);
  }

  // Used to set default values to palette
  private SetDefaultColors() {
    this.primaryBackgroundColor.hex = this.localCustomBrandingThemeData.PrimaryBackgroundColor;
    this.primaryFontColor.hex = this.localCustomBrandingThemeData.PrimaryFontColor;
    this.menuBackgroundColor.hex = this.localCustomBrandingThemeData.MenuBackgroundColor;
    this.menuFontColor.hex = this.localCustomBrandingThemeData.MenuFontColor;
    this.ctaBackgroundColor.hex = this.localCustomBrandingThemeData.CTABackgroundColor;
    this.ctaFontColor.hex = this.localCustomBrandingThemeData.CTAFontColor;
  }

  // Used to hide any palette opened
  private HideAllPalattes() {
    this.isPaletteSelected.forEach((element, index) => {
      this.isPaletteSelected[index] = false;
    });
  }

  // Used to show the particular palette clicked
  private TogglePalette(index: number) {
    this.HideAllPalattes();
    this.isPaletteSelected[index] = !this.isPaletteSelected[index];
  }

  // Check whether light mode logo is clicked such that light mode logo url can be filled
  private LightLogoClicked(val: boolean) {
    this.$emit('light-logo-image-clicked', val);
  }

  // Used to get light mode logo url
  private GetLightModeLogoUrl() {
    return this.localCustomBrandingThemeData.LightModeLogoUrl === null || this.localCustomBrandingThemeData.LightModeLogoUrl === '' ? this.addIcon : this.localCustomBrandingThemeData.LightModeLogoUrl;
  }

  // Used to get dark mode logo url
  private GetDarkModeLogoUrl() {
    return this.localCustomBrandingThemeData.DarkModeLogoUrl === null || this.localCustomBrandingThemeData.DarkModeLogoUrl === '' ? this.addIcon : this.localCustomBrandingThemeData.DarkModeLogoUrl;
  }

  // Used to get the information related to CTA button
  private GetCTABtnInformationString(val: boolean) {
    this.showCTABtnInformationString = val;
  }

  // Watches
  @Watch('primaryBackgroundColor')
  private CheckChangeInPrimaryBackgroundColor(newColor: { hex: '' }) {
    this.localCustomBrandingThemeData.PrimaryBackgroundColor = newColor.hex;
    this.lightModeLogoData.ImageBackground = newColor.hex;
  }
  @Watch('primaryFontColor')
  private CheckChangeInPrimaryFontColor(newColor: { hex: '' }) {
    this.localCustomBrandingThemeData.PrimaryFontColor = newColor.hex;
  }
  @Watch('menuBackgroundColor')
  private CheckChangeInMenuBackgroundColor(newColor: { hex: '' }) {
    this.localCustomBrandingThemeData.MenuBackgroundColor = newColor.hex;
  }
  @Watch('menuFontColor')
  private CheckChangeInMenuFontColor(newColor: { hex: '' }) {
    this.localCustomBrandingThemeData.MenuFontColor = newColor.hex;
  }
  @Watch('ctaBackgroundColor')
  private CheckChangeInCTABackgroundColor(newColor: { hex: '' }) {
    this.localCustomBrandingThemeData.CTABackgroundColor = newColor.hex;
  }
  @Watch('ctaFontColor')
  private CheckChangeInCTAFontColor(newColor: { hex: '' }) {
    this.localCustomBrandingThemeData.CTAFontColor = newColor.hex;
  }
  @Watch('localCustomBrandingThemeData')
  private CheckChangeInlocalCustomBrandingThemeData(val: CustomBrandingViewModel) {
    this.$emit('custom-branding-theme-data', val);
  }
  @Watch('customBrandingThemeData', {deep: true})
  private CheckChangeInCustomBrandingThemeData(val: CustomBrandingViewModel) {
    this.localCustomBrandingThemeData = val;
    this.SetDefaultColors();
    this.SetLightModeLogoData();
    this.SetDarkModeLogoData();
  }
  @Watch('isCustomBranding')
  private CheckChangeInIsCustomBranding(val: boolean) {
    this.localIsCustomBranding = val;
  }
}
export default toNative(CustomBrandingTheme);

export default class CustomBrandingViewModel {
  public static createEmpty(): CustomBrandingViewModel {
    return new CustomBrandingViewModel();
  }
  constructor(
    public Id: number = 0,
    public LightModeLogoUrl: string = '',
    public DarkModeLogoUrl: string = '',
    public PrimaryBackgroundColor: string = '',
    public PrimaryFontColor: string = '',
    public MenuBackgroundColor: string = '',
    public MenuFontColor: string = '',
    public CTABackgroundColor: string = '',
    public CTAFontColor: string = '',
  ) {}
}


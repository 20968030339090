
import { Component, Prop, toNative, Vue, Watch } from 'vue-facing-decorator';
import CustomBrandingViewModel from '@/entities/EventSetting/CustomBrandingViewModel';
@Component({
  components: {
  },
})
class CustomBrandingThemePreview extends Vue {
  @Prop() private customBrandingPreviewData!: CustomBrandingViewModel;
  private localCustomBrandingPreviewData: CustomBrandingViewModel = CustomBrandingViewModel.createEmpty();
  private logoPlaceholder = require('@/assets/Images/custom-branding-placeholder-icon.svg');

  // Constants
  private readonly totalMemuItem = 8; // Total number of lines to be shown in the menu of card
  private readonly totalBodyContentLines = 5; // Total number of lines to be shown in the body of card

  // mounted life cycle hook
  private mounted() {
    this.localCustomBrandingPreviewData = Object.assign({}, this.customBrandingPreviewData);
  }

  // Watch for observing any change in prop customBrandingPreviewData
  @Watch('customBrandingPreviewData', {deep: true})
  private CheckChangeInCustomBrandingPreviewData(val: CustomBrandingViewModel) {
    this.localCustomBrandingPreviewData = val;
  }
}
export default toNative(CustomBrandingThemePreview);
